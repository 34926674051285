// App.js
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Product from './components/Product';
import Add from './components/Add';
import ProductDetails from './components/ProductDetails';

const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  colors: {
    blue: {
      300: "#b0e6eb",
      400: "#66cbd4",
      500: "#5cb5bd", 
      600: "#44939a",
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/products" />} />
            <Route path="/products" element={<Product />} />
            <Route path="/products/:id" element={<ProductDetails />} />
            <Route path="/add" element={<Add />} />
          </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;

// ./components/Product.js
import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Text,
  Spinner,
  Input,
  Grid,
  GridItem,
  Image,
  Flex,
  Heading,
  Stack,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { AddIcon } from '@chakra-ui/icons';

function Product() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // For search
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  // Fetch products from Supabase
  useEffect(() => {
    const fetchProducts = async () => {
      const { data, error } = await supabase.from('revolve_products').select('*');
      if (error) {
        console.error('Error fetching products:', error);
      } else {
        setProducts(data);
        setFilteredProducts(data); // Initialize filtered products
      }
      setLoading(false);
    };

    fetchProducts();
  }, []);

  // Handle search input change
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredProducts(products);
    } else {
      const lowercasedTerm = searchTerm.toLowerCase();
      const filtered = products.filter(
        (product) =>
          product.name.toLowerCase().includes(lowercasedTerm) ||
          product.designer.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredProducts(filtered);
    }
  }, [searchTerm, products]);

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box p={5} bg="gray.100" minH="100vh">
      {/* Header */}
      <Heading mb={5} textAlign="center">
        Product Catalog
      </Heading>

      {/* Add Button and Search Bar Container */}
      <Flex
        direction={['column', 'column', 'row']} // Stack vertically on smaller screens
        align="center"
        justify="space-between"
        mb={6}
        maxW="800px" // Adjust max width as needed
        mx="auto" // Center the container horizontally
        gap={4} // Space between button and input
      >

        {/* Search Bar */}
        <Input
          placeholder="Search by name or designer..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="md"
          bg="white"
          flex="1"
        />
                <Button
          leftIcon={<AddIcon />}
          variant="solid"
          onClick={() => navigate('/add')}
          width={['100%', '100%', 'auto']} // Full width on small screens
          colorScheme='blue'
        >
          Add Product
        </Button>
      </Flex>

      {/* Products Grid */}
      {filteredProducts.length > 0 ? (
        <Grid
          templateColumns={[
            'repeat(1, 1fr)', // base
            'repeat(2, 1fr)', // sm
            'repeat(3, 1fr)', // md and above
            'repeat(4, 1fr)', // md and above
          ]}
          gap={6}
          maxW="1200px" // Increased max width for better centering
          mx="auto" // Center the grid horizontally
        >
          {filteredProducts.map((product) => (
            <GridItem
              key={product.id}
              bg="white"
              borderRadius="md"
              boxShadow="md"
              overflow="hidden"
              cursor="pointer" // Change cursor to pointer on hover
              transition="transform 0.2s"
              _hover={{ transform: 'scale(1.02)', boxShadow: 'lg' }}
              onClick={() => navigate(`/products/${product.id}`)} // Navigate on click
            >
              {/* Product Image Container */}
              <Box width="100%" height="300px" align="center">
                <Image
                  src={product.images[0]}
                  alt={product.name}
                  objectFit="cover"
                  width="auto"
                  height="100%"

                />
              </Box>

              {/* Product Details */}
              <Box p={4}>
                <Stack spacing={2}>
                  <Text fontSize="lg" fontWeight="bold">
                    {product.name}
                  </Text>
                  <Text color="gray.500">{product.designer}</Text>
                  <Text fontSize="lg" fontWeight="semibold">
                    {product.price}
                  </Text>
                </Stack>
              </Box>
            </GridItem>
          ))}
        </Grid>
      ) : (
        <Text textAlign="center" color="gray.500">
          No products found.
        </Text>
      )}
    </Box>
  );
}

export default Product;

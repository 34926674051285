import React, { useState } from 'react';
import {
  Box,
  Input,
  Button,
  useToast,
  Spinner,
  Text,
  Heading,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Add = () => {
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState(null);
  const toast = useToast();
  const navigate = useNavigate(); // React Router hook for navigation

  const handleSubmit = async () => {
    if (!url) {
      toast({
        title: 'Error',
        description: 'Please enter a valid URL',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post('https://hoobe-revolve-scraper.onrender.com/add', { url });

      if (response.data && response.data.product) {
        setProductData(response.data.product);

        toast({
          title: 'Success',
          description: 'Product added successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Redirect to /products/:id
        navigate(`/products/${response.data.product.id}`);
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add product. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      bg="gray.100"
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Box
        maxW="sm"
        w="100%"
        mx="auto"
        p={6}
        bg="white"
        borderWidth={1}
        borderRadius="lg"
        boxShadow="md"
      >
        <VStack spacing={4} align="stretch">
          <Box display="flex" alignItems="center">
            <IconButton
              icon={<ArrowBackIcon />}
              aria-label="Go back"
              onClick={() => navigate('/products')}
              mr={2}
            />
            <Heading size="lg" textAlign="center" flex="1">
              Add Product
            </Heading>
          </Box>
          <Input
            placeholder="Enter Revolve product URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            isDisabled={isLoading}
          />
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={isLoading}
            width="full"
          >
            {isLoading ? <Spinner size="sm" /> : 'Add Product'}
          </Button>

          {productData && (
            <Box mt={4} p={4} borderWidth={1} borderRadius="lg" bg="gray.50">
              <Text fontWeight="bold" mb={2}>
                Product Data:
              </Text>
              <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                {JSON.stringify(productData, null, 2)}
              </pre>
            </Box>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default Add;

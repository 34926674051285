// ./components/ProductDetails.js
import { useState, useEffect, useCallback } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import {
  Box,
  Text,
  Spinner,
  Image,
  Link,
  Button,
  VStack,
  IconButton,
  HStack,
  Flex,
  Stack,
  useBreakpointValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

// Motion-enhanced components
const MotionFlex = motion(Flex);
const MotionBox = motion(Box);

const swipeThreshold = 10000;

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [direction, setDirection] = useState(0);
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });

  useEffect(() => {
    const fetchProduct = async () => {
      const { data, error } = await supabase
        .from('revolve_products')
        .select('*')
        .eq('id', id)
        .single();

      if (error) console.error('Error fetching product:', error);
      else setProduct(data);
      setLoading(false);
    };
    fetchProduct();
  }, [id]);

  const handleNext = useCallback(() => {
    setDirection(1);
    setCurrentImage((prev) => (prev + 1) % product.images.length);
  }, [product?.images.length]);

  const handlePrev = useCallback(() => {
    setDirection(-1);
    setCurrentImage((prev) => (prev - 1 + product.images.length) % product.images.length);
  }, [product?.images.length]);

  const variants = {
    enter: (dir) => ({ x: dir > 0 ? 300 : -300, opacity: 0 }),
    center: { x: 0, opacity: 1 },
    exit: (dir) => ({ x: dir < 0 ? 300 : -300, opacity: 0 }),
  };

  const handleDragEnd = (e, { offset, velocity }) => {
    const swipe = Math.abs(offset.x) * velocity.x;
    if (swipe < -swipeThreshold) handleNext();
    else if (swipe > swipeThreshold) handlePrev();
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl"/>
      </Box>
    );

  if (!product)
    return (
      <Box textAlign="center" mt={10}>
        <Text fontSize="xl">Product not found.</Text>
      </Box>
    );

  const hasImages = product.images?.length > 0;

  return (
    <MotionFlex
      maxW="800px"
      mx="auto"
      bg="white"
      overflow="hidden"
      flexDirection={flexDirection}
      mt={10}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      h="580px"
    >
      {/* Image Section */}
      <Box flex="1" position="relative" overflow="hidden" borderRadius="md" mr={{ md: 6 }} mb={{ base: 6, md: 0 }}>
        {hasImages ? (
          <AnimatePresence custom={direction} mode="wait">
            <MotionBox
              key={currentImage}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              style={{ position: 'absolute', width: '100%', height: '100%' }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={handleDragEnd}
            >
              <Image
                src={product.images[currentImage]}
                alt={product.name}
                width="100%"
                height="100%"
                objectFit="cover"
                borderRadius="md"
                boxShadow="xl"
                loading="lazy"
                userSelect="none"
                pointerEvents="none"
              />
            </MotionBox>
          </AnimatePresence>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            bg="gray.200"
            borderRadius="md"
          >
            <Text color="gray.500">No Images Available</Text>
          </Box>
        )}

        {hasImages && (
          <>
            <IconButton
              aria-label="Previous Image"
              icon={<ChevronLeftIcon />}
              onClick={handlePrev}
              position="absolute"
              top="50%"
              left="10px"
              transform="translateY(-50%)"
              zIndex="1"
              size="lg"
              color="white"
              variant="ghost"
              _hover={{ color: 'black' }}
            />
            <IconButton
              aria-label="Next Image"
              icon={<ChevronRightIcon />}
              onClick={handleNext}
              position="absolute"
              top="50%"
              right="10px"
              transform="translateY(-50%)"
              zIndex="1"
              size="lg"
              color="white"
              variant="ghost"
              _hover={{ color: 'black' }}
            />
            <HStack
              position="absolute"
              bottom="10px"
              left="50%"
              transform="translateX(-50%)"
              bg="rgba(0, 0, 0, 0.6)"
              px={3}
              py={1}
              borderRadius="md"
            >
              <Text fontSize="sm" color="white">
                {currentImage + 1} / {product.images.length}
              </Text>
            </HStack>
          </>
        )}
      </Box>

      {/* Product Details */}
      <Box flex="1">
        <Breadcrumb mb={4}>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/products">
              Products
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{product.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <VStack align="start" spacing={2} mb={4}>
          <Text fontSize="3xl" fontWeight="extrabold">
            {product.name}
          </Text>
          <Text fontSize="lg" color="gray.600">
            by {product.designer}
          </Text>
        </VStack>

        <Stack spacing={3}>
          <Text><strong>Price:</strong> {product.price}</Text>
          <Text><strong>Color:</strong> {product.color}</Text>
          <Text><strong>Product Code:</strong> {product.product_code}</Text>
          <Text><strong>Sizes:</strong> {product.sizes.join(', ')}</Text>
        </Stack>

        <Link href={product.product_url} isExternal>
          <Button
            colorScheme="blue"
            mt={6}
            size="sm"
            rightIcon={<ChevronRightIcon />}
            _hover={{ bg: 'blue.600' }}
          >
            View Product on Website
          </Button>
        </Link>
      </Box>
    </MotionFlex>
  );
};

export default ProductDetails;
